import React, { useContext, useEffect, useMemo, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import {
  approveVendor,
  declineVendor,
} from "../../PendingApproval/Vendor/Helper";
import { deleteVendor } from "./Helper";
import { getAllVendors, getVendorChildUsers, vendorListHeaders } from "./Helper";
import { IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import { Button, TablePagination } from "@mui/material";
import { useDebounce, useQueryParams } from "../../../hooks";
import { DeactivateUserModal } from "../../../components/common";


const VendorUserList = ({ isChildUser = false }) => {
  const context = useOutletContext();
  const [vendor, setVendor] = useState([]);
  const { getParam, getAllQueryParamString, setMultiParam, removeParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [vendorPage, setVendorPage] = useState(parseInt(currentPage) || 0);
  const [vendorLimit, setVendorLimit] = useState(parseInt(currentLimit) || 9);
  const [vendorTotal, setVendorTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({ id: "" });
  const debounceSearch = useDebounce(search, 1000);
  const { userid } = useParams()

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, vendorLimit);
    setVendorPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setVendorLimit(parseInt(event.target.value, 10));
    setVendorPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    if (isChildUser) {
      getVendorChildUsers(page, limit, '', userid).then((data) => {
        setVendor(data.data.result);
        setVendorTotal(data.data.count);
      })
    } else {
      getAllVendors(page, limit)
        .then((data) => {
          setVendor(data.data.vendors.rows);
          setVendorTotal(data.data.vendors.totalItems);
        })
        .catch((err) => console.log(err));
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setMultiParam({ "page": vendorPage, "pageSize": vendorLimit });
  }, [vendorPage, vendorLimit]);

  useEffect(() => {
    context(["User Manager", "Vendor"]);
    if (isChildUser) {
      getVendorChildUsers(vendorPage, vendorLimit, debounceSearch, userid).then((data) => {
        setVendor(data.data.result);
        setVendorTotal(data.data.count);
      })
    } else {
      getAllVendors(vendorPage, vendorLimit, debounceSearch)
        .then((data) => {
          setVendor(data.data.vendors.rows);
          setVendorTotal(data.data.vendors.totalItems);
        })
        .catch((err) => console.log(err));
    }
  }, [toggle, vendorPage, vendorLimit, debounceSearch]);
  const viewClient = (e, id) => {
    e.preventDefault();
  };

  const removeVendor = () => {
    deleteVendor(vendorInfo.id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => { }, [toggle]);

  const vendorMapping = useMemo(() => {
    return vendor.map((item) => ({
      ...item,
      // clients: <Link to={`cvr-list/${item?.id}?connectiontitle=CLIENT`}>View Clients List</Link>,
      // vendors: <Link to={`cvr-list/${item?.id}?connectiontitle=VENDOR`}>View Vendors List</Link>,
      // resources: <Link to={`cvr-list/${item?.id}?connectiontitle=RESOURCE`}>View Resources List</Link>,
    }))
  }, [vendor]);

  const renderVendorList = (item, index) => (
    <th scope="col" class="py-3 px-6 uppercase" key={index}>{item}</th>
  )

  const handleNavigateVendorInfo = (id, userName) => {
    navigate(`vendor-info/${id}${getAllQueryParamString}&&user-name=${userName}`)
  }

  const switchHandler = async (vendorDetails, val) => {
    await approveVendor(vendorDetails.id, false, { selfManagedResources: !!val })
    if (isChildUser) {
      getVendorChildUsers(vendorPage, vendorLimit, debounceSearch, userid).then((data) => {
        setVendor(data.data.result);
        setVendorTotal(data.data.count);
      })
    } else {
      getAllVendors(vendorPage, vendorLimit, debounceSearch)
        .then((data) => {
          setVendor(data.data.vendors.rows);
          setVendorTotal(data.data.vendors.totalItems);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <div className={`p-5 shadow-md bg-white ${isChildUser ? 'mt-8 m-8' : ''}`}>
      {open && <DeactivateUserModal open={open} setOpen={setOpen} title={"Delete this Vendor"} deactivateUser={removeVendor} />}
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          {isChildUser ? 'Child Users' : 'Vendors'}
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>{vendorListHeaders.map(renderVendorList)}</tr>
          </thead>
          <tbody>
            {vendorMapping?.map((vend, index) => {
              return (
                <tr class="bg-white border-b hover:bg-slate-50">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{vend?.createdAt?.split("T")[0]}</td>
                  <td class="py-4 px-6 cursor-pointer hover:underline" onClick={() => handleNavigateVendorInfo(vend?.id, `${vend?.company}`)}>
                    {vend?.firstName ? vend?.firstName + " " + vend?.lastName : vend?.name || ''}
                  </td>
                  <td class="py-4 px-6">{vend?.username}</td>
                  {/* <td class="py-4 px-6">{vend.clients}</td>
                  <td class="py-4 px-6">{vend.vendors}</td>
                  <td class="py-4 px-6">{vend.resources}</td> */}
                  <td class="py-4 px-6">{vend?.email}</td>
                  <td class="py-4 px-6">{vend?.phone}</td>
                  <td class="py-4 px-6">{vend?.company}</td>
                  <td class="py-4 px-6">
                    <b>
                      {vend?.isAccountApproved ? "Active" : "Approval Pending"}
                    </b>
                  </td>
                  <td class="py-4 px-6">
                    <Switch
                      checked={vend?.selfManagedResources}
                      onChange={(e) => switchHandler(vend, e.target.checked)}
                      color="primary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </td>
                  <td class="py-4 px-6">
                    <div className="grid grid-cols-3 gap-2">
                      <IconButton aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="login">
                        <LoginIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => (e.preventDefault(), setOpen(true), setVendorInfo({ id: vend?.id }))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={vendorTotal}
        page={vendorPage}
        onPageChange={handleChangePage}
        rowsPerPage={vendorLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default VendorUserList;
