import React, { useState, useEffect } from "react";
import logo from "./../assets/logo.png";
import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../pages/Auth/Helper";
function Sidebar() {
  let location = useLocation();
  let navigate = useNavigate();
  let [selectedpanel, setselectedpanel] = useState("dashboard");
  let [drop, setdrop] = useState(0);
  let [toggle, setToggle] = useState(true);
  let [arrow, setarrow] = useState({
    pendingApproval: false,
    user: false,
    job: false,
    cms: false,
    page: false,
    email: false,
    country: false,
    buisness: false,
    faq: false,
  });
  const [active, setActive] = useState({
    pendingApproval: "hidden py-2 space-y-2",
    user: "hidden py-2 space-y-2",
    job: "hidden py-2 space-y-2",
    cms: "hidden py-2 space-y-2",
    page: "hidden py-2 space-y-2",
    email: "hidden py-2 space-y-2",
    country: "hidden py-2 space-y-2",
    buisness: "hidden py-2 space-y-2",
    faq: "hidden py-2 space-y-2",
  });
  const handleChange = (e, name) => {
    e.preventDefault();
    setselectedpanel(name);
    setdrop(0);
    if (active[name] === "py-2 space-y-2") {
      setActive({
        ...active,
        [name]: "hidden py-2 space-y-2",
      });
      setarrow({ ...arrow, [name]: false });
    } else {
      setActive({
        pendingApproval: "hidden py-2 space-y-2",
        user: "hidden py-2 space-y-2",
        job: "hidden py-2 space-y-2",
        cms: "hidden py-2 space-y-2",
        page: "hidden py-2 space-y-2",
        email: "hidden py-2 space-y-2",
        country: "hidden py-2 space-y-2",
        buisness: "hidden py-2 space-y-2",
        faq: "hidden py-2 space-y-2",
      });
      setActive({
        ...active,
        [name]: "py-2 space-y-2",
      });
      setarrow({ ...arrow, [name]: true });
      console.log(active);
      setToggle(!toggle);
    }
  };
  useEffect(() => {
    console.log("toggled");
  }, [active]);
  return (
    <div class="h-screen " aria-label="Sidebar">
      <div
        class="min-h-screen overflow-y-auto py-4 px-3  sticky left-0"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <ul class="space-y-4">
          <li>
            <a
              href="/"
              className="flex items-center w-full p-2 text-base font-normal text-gray-900 rounded-lg text-white"
            >
              <img src={logo} className="w-32" alt="Avatar" />
            </a>
          </li>
        </ul>
        <br />
        <ul>
          <li>
            <button
              onClick={(e) => (handleChange(e, "dashboard"), navigate('/Dashboard'))}
              type="button"
              style={{
                backgroundColor:
                  selectedpanel === "dashboard" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal hover:bg-[#E6FCF7] ${
                selectedpanel === "dashboard"
                  ? "text-white"
                  : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Dashboard
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={(e) => handleChange(e, "pendingApproval")}
              type="button"
              style={{
                backgroundColor:
                  selectedpanel === "pendingApproval" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "pendingApproval"
                  ? "text-white"
                  : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Pending Approval
              </span>
              {arrow.pendingApproval ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.pendingApproval}
              style={{ backgroundColor: "#E6FCF7" }}
            >
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("pendingApproval");
                }}
              >
                <Link
                  to="/PendingApproval/ClientApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "pendingApproval" && drop === 1
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Client Approval
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(2);
                  setselectedpanel("pendingApproval");
                }}
              >
                <Link
                  to="/PendingApproval/JobApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "pendingApproval" && drop === 2
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Job Approval
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(3);
                  setselectedpanel("pendingApproval");
                }}
              >
                <Link
                  to="/PendingApproval/ProjectApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "pendingApproval" && drop === 3
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Project Approval
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(4);
                  setselectedpanel("pendingApproval");
                }}
              >
                <Link
                  to="/PendingApproval/VendorApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "pendingApproval" && drop === 4
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Vendor Approval
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <button
              onClick={(e) => handleChange(e, "user")}
              type="button"
              style={{
                backgroundColor: selectedpanel === "user" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "user" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                User Manager
              </span>
              {arrow.user ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.user}
              style={{ backgroundColor: "#E6FCF7" }}
            >
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("user");
                }}
              >
                <Link
                  to="/UserManager/Client"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "user" && drop === 1
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  List of Clients
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(2);
                  setselectedpanel("user");
                }}
              >
                <Link
                  to="/UserManager/Vendor"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "user" && drop === 2
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  List of Vendors
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(3);
                  setselectedpanel("user");
                }}
              >
                <Link
                  to="/UserManager/Consultant"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "user" && drop === 3
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  List of Consultants
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <button
              onClick={(e) => handleChange(e, "job")}
              type="button"
              style={{
                backgroundColor: selectedpanel === "job" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "job" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Job Manager
              </span>
              {arrow.job ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.job}
              style={{ backgroundColor: "#E6FCF7" }}
            >
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("job");
                }}
              >
                <Link
                  to="/JobManager/Job"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 1
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Manage Jobs
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(2);
                  setselectedpanel("job");
                }}
              >
                <Link
                  to="/JobManager/JobSub"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 2
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Manage Job Submittals
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(3);
                  setselectedpanel("job");
                }}
              >
                <Link
                  to="/JobManager/Project"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 3
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Manage Projects
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(4);
                  setselectedpanel("job");
                }}
              >
                <Link
                  to="/JobManager/Skill"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 4
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Manage Skills
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <button
              onClick={(e) => handleChange(e, "cms")}
              type="button"
              style={{
                backgroundColor: selectedpanel === "cms" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "cms" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                CMS Content
              </span>
              {arrow.cms ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.cms}
              style={{ backgroundColor: "#E6FCF7" }}
            >
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("cms");
                }}
              >
                <Link
                  to="/CMSManager/ClientApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "cms" && drop === 1
                      ? "text-white"
                      : "text-gray-400"
                  }`}
                >
                  List of Pages
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("cms");
                }}
              >
                <Link
                  to="/CMSManager/JobApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 2
                      ? "text-white"
                      : "text-gray-400"
                  }`}
                >
                  Manage Page Names
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("job");
                }}
              >
                <Link
                  to="/JobManager/ProjectApproval"
                  className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "job" && drop === 3
                      ? "text-white"
                      : "text-gray-400"
                  }`}
                >
                  Manage metadata
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <button
              onClick={(e) => handleChange(e, "email")}
              type="button"
              style={{
                backgroundColor: selectedpanel === "email" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "email" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Email Templates
              </span>
              {arrow.email ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.email}
              style={{ backgroundColor: "#E6FCF7" }}
            >
              <li
                onClick={() => {
                  setdrop(1);
                  setselectedpanel("email");
                }}
              >
                <Link
                  to="/EmailTemplates/Templates"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "email" && drop === 1
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  List of Templates
                </Link>
              </li>
              <li
                onClick={() => {
                  setdrop(2);
                  setselectedpanel("email");
                }}
              >
                <Link
                  to="/EmailTemplates/Templates/Design"
                  className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${
                    selectedpanel === "email" && drop === 2
                      ? "text-black font-semibold"
                      : "text-gray-400 font-normal"
                  }`}
                >
                  Template Design
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <button
              onClick={(e) => handleChange(e, "country")}
              type="button"
              style={{
                backgroundColor: selectedpanel === "country" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "country" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Country Manager
              </span>
              {arrow.country ? (
                <svg
                  sidebar-toggle-item
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="16"
                  className="w-6 h-6"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                    fill="#9E9EA6"
                  />
                </svg>
              )}
            </button>
            <ul
              id="dropdown-example"
              className={active.country}
              style={{ backgroundColor: "#E6FCF7" }}
            ></ul>
          </li>

          <li>
            <button
              onClick={(e) => {
                handleChange(e, "buisness");
                navigate("/BusinessType");
              }}
              type="button"
              style={{
                backgroundColor: selectedpanel === "buisness" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "buisness" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Business Types
              </span>
            </button>
          </li>
          
          <li>
            <button
              onClick={(e) => {
                handleChange(e, "jobcategory");
                navigate("/JobCategory");
              }}
              type="button"
              style={{
                backgroundColor: selectedpanel === "jobcategory" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "jobcategory" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                Job Category
              </span>
            </button>
          </li>

          <li>
            <button
              onClick={(e) => {
                handleChange(e, "faq");
                navigate("/FAQ");
              }}
              type="button"
              style={{
                backgroundColor: selectedpanel === "faq" ? "#2EBAA3" : "",
              }}
              className={`flex items-center w-full p-4 text-base font-normal  hover:bg-[#E6FCF7] ${
                selectedpanel === "faq" ? "text-white" : "text-gray-400"
              }`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap"
                sidebar-toggle-item
              >
                FAQ Manager
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={(e) => {
                logout()
                navigate("/Login")
              }}
              type="button"
              className={`flex items-center w-full p-4 text-base font-normal text-white  hover:bg-[#E6FCF7]`}
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <span
                className="flex-1 ml-3 text-left whitespace-nowrap text-gray-400"
                sidebar-toggle-item
              >
                Logout
              </span>
              <span
                className="flex-1 ml-3 text-right whitespace-nowrap text-gray-400"
                sidebar-toggle-item
              >
                <LogoutIcon />
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
