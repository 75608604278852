import api, { baseURL } from "../../../../utils/api"

export const getNewVendors = (page, limit) =>{
	return api.get(`${baseURL}/user/newvendors?page=${page}&size=${limit}`);
}

export const approveVendor = (vendor, isSwitchAccount = false, details = {}) => {
	return api.post(`${baseURL}/auth/approveuser`, { id: vendor, isSwitchAccount, ...details });
}

export const declineVendor = (vendor) =>{
	return api.post(`${baseURL}/auth/declineuser`, {id: vendor});
}

export const deleteVendor = (vendorId) =>{
	return api.delete(`${baseURL}/user/deleteuser?id=${vendorId}`);
}