import api, { baseURL } from "../../../../utils/api"

export const createEmailTemplate = (emailTemplate) =>{
	return api.post(`${baseURL}/email/addemailtemplate`, emailTemplate);
}

export const updateEmailTemplate = (emailTemplate) =>{
	return api.put(`${baseURL}/email/updateemailtemplate`, emailTemplate);
}
export const updateEmailTemplateDesign = (emailTemplate) =>{
	return api.put(`${baseURL}/email/updateemailtemplatedesign`, emailTemplate);
}

export const getEmailTemplates = (page, limit , search = '') =>{
	return api.get(`${baseURL}/email/getemailtemplates?page=${page}&size=${limit}&search=${search}`);
}

export const getEmailTemplateByID = (id) =>{
	return api.get(`${baseURL}/email/getemailtemplate?id=${id}`);
}

export const getEmailTemplateDesignByID = (id) =>{
	return api.get(`${baseURL}/email/getemailtemplatedesign?id=${id}`);
}

export const deleteEmailTemplate = (id) =>{
	return api.delete(`${baseURL}/email/deleteemailtemplate?id=${id}`);
}