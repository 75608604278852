import { Box, Button, TablePagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import MuiModal from "../../../components/MuiModal";
import { approveVendor, deleteVendor, getNewVendors } from "./Helper";
import { useQueryParams } from "../../../hooks";
import { DeactivateUserModal } from "../../../components/common";

const PendingVendorList = () => {
  const context = useOutletContext();
  const [vendors, setVendor] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [vendorPage, setVendorPage] = useState(parseInt(currentPage) || 0);
  const [vendorLimit, setVendorLimit] = useState(parseInt(currentLimit) || 9);
  const [selectedDetails, setSelectDetails] = useState(null);
  const [vendorTotal, setVendorTotal] = useState(0);
  const [currentApproval, setCurrentApproval] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({ id: "" });

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, vendorLimit);
    setVendorPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setVendorLimit(parseInt(event.target.value, 10));
    setVendorPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getNewVendors(page, limit)
      .then((data) => {
        setVendor(data.data.vendors.rows);
        setVendorTotal(data.data.vendors.totalItems);
      })
      .catch((err) => console.log(err));
  };
  const navigate = useNavigate();
  useEffect(() => {
    context(["Pending Approvals", "Vendor Approvals"]);
    getNewVendors(vendorPage, vendorLimit)
      .then((data) => {
        console.log(data.data.vendors.rows);
        setVendor(data.data.vendors.rows);
        setVendorTotal(data.data.vendors.totalItems);
      })
      .catch((err) => console.log(err));
  }, [toggle]);

  useEffect(() => {
    setMultiParam({"page": vendorPage, "pageSize": vendorLimit});
  }, [vendorPage, vendorLimit])

  const viewClient = (e, id) => {
    e.preventDefault();
  };
  const approve = (e, id, isChange = false) => {
    e.preventDefault();
    approveVendor(id, isChange)
      .then((data) => {
        setToggle(!toggle);
        setSelectDetails(null)
        setCurrentApproval(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDecline = () => {
    deleteVendor(vendorInfo.id)
      .then((data) => {
        setToggle(!toggle);
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="p-5 shadow-md bg-white">
      {openDeleteModal && (
        <DeactivateUserModal 
          open={openDeleteModal} 
          setOpen={setOpenDeleteModal} 
          title="Delete this Vendor" 
          deactivateUser={handleDecline} 
        />
      )}
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Vendor Approvals
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6 uppercase">
                ID
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Company
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Contact Person
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Email ID
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Phone Number
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Website
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Country
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Date
              </th>
              {/* <th scope="col" class="py-3 px-6 uppercase">
                      State
                    </th>
                    <th scope="col" class="py-3 px-6 uppercase">
                      City
                    </th>
                    <th scope="col" class="py-3 px-6 uppercase">
                      Zip
                    </th>
                    <th scope="col" class="py-3 px-6 uppercase">
                      Address
                    </th> */}
              <th scope="col" className="py-3 px-6 uppercase">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {vendors?.map((vendor, index) => (
              <tr className="bg-white border-b" key={vendor.id}>
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                >
                  {index + 1}
                </th>
                <td className="py-4 px-6">{vendor?.company}</td>
                <td className="py-4 px-6">
                  {vendor?.firstName + " " + vendor?.lastName}
                </td>
                <td className="py-4 px-6">{vendor?.email}</td>
                <td className="py-4 px-6">{vendor?.phone}</td>
                <td className="py-4 px-6">{vendor?.website}</td>
                <td className="py-4 px-6">{vendor?.country}</td>
                <td className="py-4 px-6">{vendor?.createdAt.split("T")[0]}</td>
                <td className="py-2 px-4">
                  <div className="flex flex-col gap-2">
                    <Button
                    className="!text-xs"
                      size="small"
                      variant="outlined"
                      onClick={() => setSelectDetails(vendor?.id)}
                    >
                      Approve
                    </Button>
                    <Button
                    className="!text-xs"
                      size="small"
                      style={{ color: "red", borderColor: "red" }}
                      variant="outlined"
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setVendorInfo({ id: vendor?.id });
                      }}
                    >
                      Decline
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={vendorTotal}
        page={vendorPage}
        onPageChange={handleChangePage}
        rowsPerPage={vendorLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
      <MuiModal open={selectedDetails} onCancel={() => setSelectDetails(null)}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Approval
          </Typography>
          <Typography variant="body1" paragraph>
            Please ensure you have reviewed all the details of the { currentApproval ? 'Vendor' : 'Client' } before proceeding with the approval. Once approved, this action cannot be undone.
          </Typography>
          <Typography variant="body1" paragraph>
            Do you want to proceed with the approval?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
            <Button onClick={() => setSelectDetails(null)} variant="outlined">
              Cancel
            </Button>
            <Button onClick={() => setCurrentApproval(!currentApproval)} variant="contained" color="primary" sx={{ color: 'white' }}>
              Change Role
            </Button>
            <Button onClick={(e) => approve(e, selectedDetails, !currentApproval)} variant="contained" sx={{ background: '#0B85BA' }} color="secondary">
              Approve As { currentApproval ? 'Vendor' : 'Client' }
            </Button>
          </Box>
        </Box>
      </MuiModal>
    </div>
  );
};

export default PendingVendorList;
