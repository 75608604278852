import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { deleteClient, getAllClients } from "./Helper";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import { Button, TablePagination } from "@mui/material";
import { declineClient } from "../../PendingApproval/Client/Helper";
import { useDebounce, useQueryParams } from "../../../hooks";
import { DeactivateUserModal } from "../../../components/common";

const ClientUserList = () => {
  const context = useOutletContext();
  const [clients, setClients] = useState([]);
  const { getParam, getAllQueryParamString, setMultiParam, removeParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [clientsPage, setClientsPage] = useState(parseInt(currentPage) || 0);
  const [clientsLimit, setClientsLimit] = useState(parseInt(currentLimit) || 10);
  const [clientsTotal, setClientsTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState({id: ""});
  const debounceSearch = useDebounce(search, 1000);
  const handleChangePage = (event, newPage) => {
    fetchData(newPage, clientsLimit);
    setClientsPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setClientsLimit(parseInt(event.target.value, 10));
    setClientsPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getAllClients(page, limit)
      .then((data) => {
        setClients(data.data.clients.rows);
        setClientsTotal(data.data.clients.totalItems);
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();
  useEffect(() => {
    context(["User Manager", "Client"]);
    getAllClients(clientsPage, clientsLimit, debounceSearch)
      .then((data) => {
        setClients(data.data.clients.rows);
        setClientsTotal(data.data.clients.totalItems);
      })
      .catch((err) => console.log(err));
  }, [debounceSearch]);

  useEffect(() => {
    removeParam("user-name")
  }, [toggle]);

  useEffect(() => {
    setMultiParam({"page": clientsPage, "pageSize": clientsLimit});
  }, [clientsPage, clientsLimit])

  const viewClient = (e, id) => {
    e.preventDefault();
  };
  
  const removeClient = () => {
    deleteClient(clientInfo.id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mappedClients = useMemo(() => {
    return clients.map((item) => ({
      ...item,
      // vendorId: <Link to={`vendor-list/${item?.id}`}>View Vendors List</Link>
    }))
  },[clients])

  const handleNavigateUserInfo = (id, name) => {
    navigate(`client-info/${id}${getAllQueryParamString}&&user-name=${name}`)
  }

  return (
    <div className="p-5 shadow-md bg-white">
      {open && <DeactivateUserModal open={open} setOpen={setOpen} title={"Delete this Client"} deactivateUser={removeClient} />}
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Clients
        </h3>
        <input
          type="text"
          id="search"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Name
              </th>
              {/* <th scope="col" class="py-3 px-6 uppercase">
                Vendors List
              </th> */}
              <th scope="col" class="py-3 px-6 uppercase">
                Date Created
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Username
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Email ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Mobile Number
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Company
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Status
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {mappedClients.map((client, index) => {
              return (
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6 cursor-pointer hover:underline" onClick={()=> handleNavigateUserInfo(client?.id, `${client?.company}`)}>
                    {client?.firstName + " " + client?.lastName}
                  </td>
                  {/* <td class="py-4 px-6">{client?.vendorId}</td> */}
                  <td class="py-4 px-6">{client?.createdAt?.split("T")[0]}</td>
                  <td class="py-4 px-6">{client?.username}</td>
                  <td class="py-4 px-6">{client?.email}</td>
                  <td class="py-4 px-6">{client?.phone}</td>
                  <td class="py-4 px-6">{client?.company}</td>
                  <td class="py-4 px-6">
                    <b>
                      {client?.isAccountApproved
                        ? "Active"
                        : "Approval Pending"}
                    </b>
                  </td>
                  <td class="py-4 px-6">
                    <div className="grid grid-cols-3 gap-2">
                      <IconButton aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="login">
                        <LoginIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => (e.preventDefault(), setOpen(true), setClientInfo({id: client?.id}))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={clientsTotal}
        page={clientsPage}
        onPageChange={handleChangePage}
        rowsPerPage={clientsLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default ClientUserList;
