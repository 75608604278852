import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { useDebounce, useQueryParams } from "../../../hooks";
import { getAllDeletedUsers } from "./Helper";

const DeletedUsersList = () => {
  const context = useOutletContext();
  const [deletedUsers, setDeletedUsers] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [usersPage, setUsersPage] = useState(parseInt(currentPage) || 0);
  const [usersLimit, setUsersLimit] = useState(parseInt(currentLimit) || 10);
  const [usersTotal, setUsersTotal] = useState(0);
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search, 1000);
  
  const handleChangePage = (event, newPage) => {
    fetchData(newPage, usersLimit);
    setUsersPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setUsersLimit(parseInt(event.target.value, 10));
    setUsersPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getAllDeletedUsers(page, limit, debounceSearch)
      .then((response) => {
        setDeletedUsers(response.data.users.rows);
        setUsersTotal(response.data.users.totalItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["User Manager", "Deleted Users"]);
    fetchData(usersPage, usersLimit);
  }, [debounceSearch]);

  useEffect(() => {
    setMultiParam({"page": usersPage, "pageSize": usersLimit});
  }, [usersPage, usersLimit])

  const mappedUsers = useMemo(() => {
    return deletedUsers.map((item) => ({
      id: item.id,
      ...item.userData,
      deletedAt: item.createdAt 
    }));
  }, [deletedUsers]);

  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Deleted Users
        </h3>
        <input
          type="text"
          id="search"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Name
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Username
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Email
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Phone
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Company
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Role
              </th>
              <th scope="col" className="py-3 px-6 uppercase">
                Date Deleted
              </th>
            </tr>
          </thead>
          <tbody>
            {mappedUsers.map((user, index) => {
              return (
                <tr className="bg-white border-b" key={user.id}>
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {index + 1}
                  </th>
                  <td className="py-4 px-6">
                    {`${user.firstName || ''} ${user.lastName || ''}`}
                  </td>
                  <td className="py-4 px-6">{user.username}</td>
                  <td className="py-4 px-6">{user.email}</td>
                  <td className="py-4 px-6">{user.phone || '-'}</td>
                  <td className="py-4 px-6">{user.company || '-'}</td>
                  <td className="py-4 px-6">{user.role}</td>
                  <td className="py-4 px-6">{user.deletedAt?.split("T")[0]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={usersTotal}
        page={usersPage}
        onPageChange={handleChangePage}
        rowsPerPage={usersLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default DeletedUsersList;
