import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { deleteVendor } from "../../PendingApproval/Vendor/Helper";
import { deleteconsultant, getAllConsultants, getAllVendors } from "./Helper";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import { Button, TablePagination } from "@mui/material";
import { DeactivateUserModal } from "../../../components";
import RecyclingIcon from '@mui/icons-material/SettingsBackupRestore';

import { useDebounce } from "../../../hooks";
const ConsultantUserList = () => {
  const context = useOutletContext();
  const [consultant, setConsultant] = useState([]);
  const [consultantPage, setConsultantPage] = useState(0);
  const [consultantLimit, setConsultantLimit] = useState(9);
  const [consultantTotal, setConsultantTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [open, setOpen] = useState(false);
  const [consultantInfo, setConsultantInfo] = useState({id: ""});
  const [search, setSearch] = useState('')
  const debounceSearch = useDebounce(search, 1000);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, consultantLimit);
    setConsultantPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setConsultantLimit(parseInt(event.target.value, 10));
    setConsultantPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getAllConsultants(page, limit, debounceSearch)
      .then((data) => {
        setConsultant(data.data.consultants.rows);
        setConsultantTotal(data.data.consultants.totalItems);
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();

  useEffect(() => {
    context(["User Manager", "Consultant"]);
    getAllConsultants(consultantPage, consultantLimit, debounceSearch)
      .then((data) => {
        setConsultant(data.data.consultants.rows);
        setConsultantTotal(data.data.consultants.totalItems);
      })
      .catch((err) => console.log(err));
  }, [toggle, open, debounceSearch]);

  const viewClient = (e, id) => {
    e.preventDefault();
  };
  
  const removeConsultant = () => {
    deleteconsultant(consultantInfo.id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {}, [toggle]);

  const handleRemoveUser = (e, id, isdeleted, name) => {
    setOpen(true);
  }

  return (
    <div className="p-5 shadow-md bg-white">
      {open && <DeactivateUserModal open={open} setOpen={setOpen} title={"Delete this Consultant"} deactivateUser={removeConsultant} />}
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Consultants
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Date Created
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Name
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Username
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Email ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Mobile Number
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Company
              </th>
              {/* <th scope="col" class="py-3 px-6 uppercase">
                Status
              </th> */}
              <th scope="col" class="py-3 px-6 uppercase">
                Account Status
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {consultant?.map((vend, index) => {
              return (
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{vend?.createdAt?.split("T")[0]}</td>
                  <td class="py-4 px-6">
                    {vend?.firstName + " " + vend?.lastName}
                  </td>
                  <td class="py-4 px-6">{vend?.username}</td>
                  <td class="py-4 px-6">{vend?.email}</td>
                  <td class="py-4 px-6">{vend?.phone}</td>
                  <td class="py-4 px-6">
                    <a href={`${process.env.REACT_APP_BASE_FE_URL}/${vend?.username}`} target="_blank">{vend?.vendors?.[0]?.company}</a>
                  </td>
                  {/* <td class="py-4 px-6">
                    <b>
                      {vend?.isAccountApproved ? "Active" : "Approval Pending"}
                    </b>
                  </td> */}
                  <td class="py-4 px-6">
                    <b>
                      {vend?.isdeleted ? "Deactive" : "Active"}
                    </b>
                  </td>
                  <td class="py-4 px-6">
                    <div className="grid grid-cols-3 gap-2">
                      <IconButton aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="login">
                        <LoginIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => (e.preventDefault(), setOpen(true), setConsultantInfo({id: vend?.id}))}
                      >
                        {vend?.isdeleted ? <RecyclingIcon /> : <DeleteIcon />}
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={consultantTotal}
        page={consultantPage}
        onPageChange={handleChangePage}
        rowsPerPage={consultantLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default ConsultantUserList;
